<template>
  <section-base>
    <div class="heading-col">
      <h1 class="color-primary-100">Strompakete</h1>

      <h2 class="color-primary-100">Wir sind bald wieder zurück.</h2>
    </div>

    <div class="color-accent-300 maintenance-content">
      <p>
        Zurzeit werden am Strompakete-System geplante Wartungsarbeiten
        durchgeführt. Bitte besuchen Sie uns in Kürze wieder.
      </p>

      <div>
        <h1>{{ maintModeDate }}</h1>

        <h1>um ca. {{ maintModeTime }}</h1>
      </div>

      <p>Vielen Dank für Ihr Verständnis! Ihr Strompakete Team</p>
    </div>

    <img src="../../assets/images/maintenance.png" />
  </section-base>
</template>

<script>
import SectionBase from '@/components/next/section-base.vue';
import { mapState } from 'vuex';

export default {
  components: { SectionBase },

  data() {
    return {
      until: null
    };
  },

  computed: {
    ...mapState('user', ['maintenanceUntil']),

    maintModeDate() {
      if (this.until) {
        const date = new Intl.DateTimeFormat('en-GB', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        }).format(this.until);

        const dateParts = date.split('/');
        return `${dateParts[0]}.${dateParts[1]}.${dateParts[2]}`;
      }

      return '';
    },

    maintModeTime() {
      if (this.until) {
        return new Intl.DateTimeFormat('en', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }).format(this.until);
      }

      return '';
    }
  },

  beforeMount() {
    this.until = this.maintenanceUntil ? new Date(this.maintenanceUntil) : null;
  }
};
</script>

<style scoped lang="scss">
.maintenance-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  text-align: center;
  gap: 1rem;
  margin-bottom: 4rem;

  h1 {
    font-weight: bold;
  }
}

img {
  max-width: 40rem;
  width: 90%;
}
</style>
